import React from "react"
import PageTypes from "../components/page-types/_PageTypes"
import Blocks from "gatsby-theme-yum-components/src/components/blocks/_Blocks"
import SbEditable from "storyblok-react"

const loadStoryblokBridge = function (cb) {
  let script = document.createElement("script")
  script.type = "text/javascript"
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${process.env.GATSBY_STORYBLOK_ACCESS_TOKEN}`
  script.onload = cb
  document.getElementsByTagName("head")[0].appendChild(script)
}

// const getParam = function(val) {
//   var result = ""
//   var tmp = []

//   window.location.search
//     .substr(1)
//     .split("&")
//     .forEach(function(item) {
//       tmp = item.split("=")
//       if (tmp[0] === val) {
//         result = decodeURIComponent(tmp[1])
//       }
//     })

//   return result
// }

const contentBlocks = (content) => (
  <div>
    {content.body &&
      content.body.map((block) =>
        React.createElement(Blocks(block.component), {
          key: block._uid,
          block: block,
          type: block.component,
        })
      )}
  </div>
)

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props)
    this.state = { story: null, globalNavigation: { content: {} } }
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents()
    })
  }

  loadStory() {
    // console.log("Load Story")
    window.storyblok.get(
      {
        slug: window.storyblok.getParam("path"),
        version: "draft",
        // resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ story: data.story })
        // console.log("Story", data.story)
        // Die globale Navigation wird hier geladen, um ggf. Attribute
        // wie "lang" aus der Story mitgeben zu können
        // this.loadGlobalNavigation()
      }
    )
  }

  // loadGlobalNavigation() {
  //   console.log("Load Navigation")

  //   // const language = lang === "default" ? "" : lang + "/"
  //   window.storyblok.get(
  //     "cdn/links",
  //     {
  //       version: "draft",
  //     },
  //     data => {
  //       this.setState({ globalNavigation: data.links })
  //       console.log("Global Navigation Story", data.links)
  //     }
  //   )
  // }

  initStoryblokEvents() {
    this.loadStory()

    let sb = window.storyblok

    sb.on(["change", "published"], (payload) => {
      this.loadStory()
    })

    sb.on("input", (payload) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        )
        this.setState({ story: payload.story })
      }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render() {
    if (this.state.story == null) {
      return <div></div>
    }

    let content = this.state.story.content
    // let navigation = this.state.globalNavigation.content

    return (
      <SbEditable content={content}>
        <div>
          {React.createElement(
            PageTypes(content.component),
            {
              key: content._uid,
              content: content,
              type: content.component,
              // navigation: navigation,
            },
            contentBlocks(content)
          )}
        </div>
      </SbEditable>
    )
  }
}

export default StoryblokEntry
